import React from 'react'
import {
  TextField,
  makeStyles,
  IconButton,
  Button,
  Collapse,
} from '@material-ui/core'
import { ArrowBack } from '@material-ui/icons'
import {
  updatePasswordMutation,
  forgotPasswordMutation,
} from '../utils/mutations'
import validateRules from '../utils/validateRules'
import handleKey from '../utils/handleKey'

type ResetPageProps = {
  setResetExpand: (value: boolean) => void
  setMessage: (value: string) => void
  setServerExpand: (value: boolean) => void
  isResetPage: boolean
  isAlreadyLogin: boolean
}

const ResetPage: React.FC<ResetPageProps> = ({
  setResetExpand,
  setMessage,
  setServerExpand,
  isResetPage,
  isAlreadyLogin,
}) => {
  const classes = useStyles()
  const [account, setAccount] = React.useState('')
  const [password, setPassword] = React.useState('')
  const [newPassword, setNewPassword] = React.useState('')
  const [confirmNewPassword, setConfirmNewPassword] = React.useState('')
  const [isSubmitting, setIsSubmitting] = React.useState(false)
  const [isForget, setIsForget] = React.useState(false)
  const [email, setEmail] = React.useState('')
  const [error, setError] = React.useState<any>({})

  const handleReset = () => {
    //reset status when leaving page
    setAccount('')
    setPassword('')
    setNewPassword('')
    setConfirmNewPassword('')
    setIsSubmitting(false)
    setIsForget(false)
    setEmail('')
    setError({})
  }

  // set error
  const rules = React.useMemo(
    () => validateRules(password, newPassword, confirmNewPassword),
    [password, newPassword, confirmNewPassword]
  )
  React.useEffect(() => {
    // validate form
    let error = {
      confirmNewPasswordError: '',
      newPasswordError: '',
    }
    rules.forEach(e => {
      if (e?.rule) {
        error = {
          ...error,
          ...{ [e?.name]: e?.message },
        }
      } else {
        error = {
          ...error,
          ...{ [e?.name]: '' },
        }
      }
      setError(error)
    })
  }, [password, newPassword, confirmNewPassword])
  //check if there are errors
  const isError = isForget
    ? !account || !email || isSubmitting
    : !account ||
      !password ||
      !newPassword ||
      Object.values(error).some(e => e) ||
      isSubmitting
  //

  //listener for enter key
  React.useEffect(() => {
    const listener = handleKey(isResetPage && !isError, handleSubmit)
    return () => {
      document.removeEventListener('keydown', listener)
    }
  }, [isResetPage, isError])
  //

  const handleSubmit = () => {
    if (isError) return null
    setIsSubmitting(true)
    if (isForget) {
      forgotPasswordMutation(account, email).then(({ data, errors }) => {
        if (errors) {
          setMessage(errors?.[0]?.message)
        } else {
          setMessage(data?.userForgotPassword)
          setResetExpand(false)
          handleReset()
        }
      })
    } else {
      updatePasswordMutation(account, password, newPassword).then(
        ({ data, errors }) => {
          setIsSubmitting(false)
          if (errors) {
            setMessage(errors?.[0]?.message)
          } else {
            if (data) {
              setMessage(data?.userUpdatePassword?.[0]?.message)
              setResetExpand(false)
              handleReset()
            }
          }
        }
      )
    }
  }
  return (
    <div>
      <div className={classes.titleWrapper}>
        <img
          src="https://companyresources.blob.core.windows.net/image/endata/icon-big.png"
          alt="logo"
          className={classes.logo}
        />
        <p className={classes.text}>
          {isForget ? 'Reset Password' : 'Change Password'}
        </p>
        <IconButton
          className={classes.iconButton}
          onClick={() => {
            setResetExpand(false)
            if (isAlreadyLogin) {
              setServerExpand(true)
              setMessage('')
            }
            setTimeout(() => {
              handleReset()
            }, 500)
          }}
        >
          <ArrowBack />
        </IconButton>
      </div>
      <div>
        <TextField
          variant="outlined"
          label="Username"
          name="account"
          fullWidth
          value={account}
          onChange={e => setAccount(e.target.value)}
          className={classes.textField}
        />
        <Collapse in={isForget}>
          <TextField
            variant="outlined"
            label="Email"
            name="email"
            type="email"
            fullWidth
            value={email}
            onChange={e => setEmail(e.target.value)}
            className={classes.textField}
          />
        </Collapse>
        <Collapse in={!isForget}>
          <TextField
            variant="outlined"
            label="Password"
            name="password"
            type="password"
            fullWidth
            value={password}
            onChange={e => setPassword(e.target.value)}
            className={classes.textField}
          />
          <TextField
            variant="outlined"
            label="New Password"
            name="new password"
            type="password"
            fullWidth
            error={Boolean(error?.newPasswordError)}
            helperText={error?.newPasswordError}
            value={newPassword}
            onChange={e => setNewPassword(e.target.value)}
            className={classes.textField}
          />
          <TextField
            variant="outlined"
            label="Confirm new password"
            name="confirm new password"
            type="password"
            fullWidth
            value={confirmNewPassword}
            error={Boolean(error?.confirmNewPasswordError)}
            helperText={error?.confirmNewPasswordError}
            onChange={e => setConfirmNewPassword(e.target.value)}
            className={classes.textField}
          />
        </Collapse>
      </div>
      <div className={classes.buttonWrapper}>
        {isForget ? (
          <div />
        ) : (
          <p className={classes.helperText} onClick={() => setIsForget(true)}>
            Reset your current password?
          </p>
        )}
        <div>
          <Button
            color="primary"
            variant="outlined"
            className={classes.button}
            disabled={isError}
            onClick={handleSubmit}
          >
            Submit
          </Button>
        </div>
      </div>
    </div>
  )
}

export default ResetPage

const useStyles = makeStyles({
  textField: {
    marginBottom: '10px',
  },
  text: {
    fontSize: '18px',
    fontWeight: 'bold',
  },
  titleWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  iconButton: {
    borderRadius: '100%',
    padding: '0 12px',
    '&:hover': {
      borderRadius: 0,
    },
  },
  button: {
    float: 'right',
  },
  helperText: {
    cursor: 'pointer',
    maxWidth: 'fit-content',
    '&:hover': {
      color: '#42a1f5',
    },
  },
  buttonWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  logo: {
    maxWidth: 'inHerit',
  },
})
